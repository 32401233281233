import services, { servicesNew } from "../../util/services";
import Moment from "react-moment";
import moment from "moment";
import React, { useState, useEffect } from "react";
import styles from  "../../pages/VetReportPDF.module.css";
import logo from "./Logo.png";
import { HeaderTag } from "./HeaderTag.tsx";

export const Header = ({
	data,
	name,
	diseaseName,
	description,
	headerTitle,
}) => {
	return (
		<>
			<header className={`${styles[name]} ${styles.header}`}>
				<div className={styles.row}>
					<div className={styles.col1}>
						<img
							src={logo}
							alt="logo Basepaws Vets"
							className={styles.logo}
						/>
					</div>
					<div className={styles.col11}>
						<div className={`${styles.dFlex} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.mb10}`}>
							<h1 className={styles.bodyBold}>
								{data?.animal?.name}'s {data?.animal?.organism == "dog" ? "Canine" : "Feline"}{" "}
								Baseline
							</h1>
							<div className={styles.dFlex}>
								<div className={`${styles.dFlex} ${styles.mr10}`}>
									<span className={`${styles.legalBold} ${styles.mr5}`}>
										Patient:
									</span>
									<span className={`${styles.headerInfo}`}>
										{data?.animal?.name}
									</span>
								</div>
								<div className={`${styles.dFlex} ${styles.mr10}`}>
									<span className={`${styles.legalBold} ${styles.mr5}`}>
										Owner:
									</span>
									<span className={`${styles.headerInfo}`}>
										{data.user.first_name +
											" " +
											data.user.last_name}
									</span>
								</div>
								<div className={`${styles.dFlex} ${styles.mr10}`}>
									<span className={`${styles.legalBold} ${styles.mr5}`}>
										Barcode ID:
									</span>
									<span className={`${styles.headerInfo}`}>
										{data.kit.code}
									</span>
								</div>
								<div className={`${styles.dFlex} ${styles.mr10}`}>
									<span className={`${styles.legalBold} ${styles.mr5}`}>
										Collection Date:
									</span>
									<span className={`${styles.headerInfo}`}>
										{data.kit.collection_date.split("T")[0]}
									</span>
								</div>
							</div>
						</div>
						{diseaseName && (
							<HeaderTag
								diseaseName={diseaseName}
								description={description}
							/>
						)}
						{headerTitle && (
							<h2 className={`${styles.bodyBigRegular}`}>{headerTitle}</h2>
						)}
					</div>
				</div>
			</header>
		</>
	);
};
