import moment from "moment";
import React, { useState, useEffect } from "react";
import styles from "./VetReportPDF.module.css";
import { Page } from "./Page.tsx";

export const PatientInformation = ({ data }) => {
    
    interface Breed {
        name: string,
        percentage: string,
        description: string, 
        title: string
    }

	const dob = () => {
		const [year, month, day] = data.animal.born_on.split("T")[0].split("-");
		return `${month}/${day}/${year}`
	};
	
    const getBreedCols = () => {		
		return [...new Set(data.breed?.map((breed: Breed) => breed.title))].map(
			(el: any,index) => {
				let breeds = data.breed?.filter((breed) => breed.title == el);				
				return (
					<div key={index} className={`${styles.col4}`}>
						<p className={`${styles.bodyBigBold} ${styles.mb10}`}>{el}</p>
						{breeds.filter(breed=>isNaN(breed.percentage) || breed.percentage>0).map((breed,index) => (
							<p key={index} className={`${styles.bodyBigRegular}`}>
								{!isNaN(breed.percentage)
									? `${parseFloat(breed.percentage)}%`
									: breed.percentage.charAt(0).toUpperCase() +
									  breed.percentage.slice(1)}{" "}
								{breed.name}
							</p>
						))}
						{data[`${el}_unknown`]>0&&<>{data[`${el}_unknown`]}% Broadly {el}</>}
					</div>
				);
			}
		);
	};
	return (
		<>
			{data && (
				<>
					<Page
						headerTitle={
							data.report_content.patientReport.header_title
						}
						data={data}
					>
						<div className={`${styles.container}`}>
							<div>
								<h2 className={`${styles.headingRegular}`}>
									Clinic Information TEST
								</h2>
								<hr />
								<div className={`${styles.mb50} ${styles.mt20}`}>
									<p className={`${styles.bodyBigBold} ${styles.mb5}`}>
										Clinic Name
									</p>
									<p className={`${styles.bodyBigRegular}`}>
										{data.clinic}
									</p>
								</div>
							</div>

							<div>
								<h2 className={`${styles.headingRegular}`}>
									Patient Information
								</h2>
								<hr />
								<div className={`${styles.mb50}`}>
									<div className={`${styles.row} ${styles.mb20} ${styles.mt20}`}>
										<div className={`${styles.col4}`}>
											<p className={`${styles.bodyBigBold} ${styles.mb5}`}>
												Pet Name
											</p>
											<p className={`${styles.bodyBigRegular}`}>
												{data.animal.name}
											</p>
										</div>
										<div className={`${styles.col4}`}>
											<p className={`${styles.bodyBigBold} ${styles.mb5}`}>
												Gender
											</p>
											<p className={`${styles.bodyBigRegular}`}>
												{data.animal.gender
													.charAt(0)
													.toUpperCase() +
													data.animal.gender.slice(1)}
											</p>
										</div>
										<div className={`${styles.col4}`}>
											<p className={`${styles.bodyBigBold} ${styles.mb5}`}>
											Date of Birth
											</p>
											<p className={`${styles.bodyBigRegular}`}>
												{dob()}
											</p>
										</div>
									</div>
									<div className={`${styles.row}`}>
										<div className={`${styles.col4}`}>
											<p className={`${styles.bodyBigBold} ${styles.mb5}`}>
												Pet Owner Name
											</p>
											<p className={`${styles.bodyBigRegular}`}>
												{data.user.first_name}{" "}
												{data.user.last_name}
											</p>
										</div>
										<div className={`${styles.col4}`}>
											<p className={`${styles.bodyBigBold} ${styles.mb5}`}>
												Species
											</p>
											<p className={`${styles.bodyBigRegular}`}>
												{data.animal.organism}
											</p>
										</div>
									</div>
								</div>
							</div>

							<div>
								<h2 className={`${styles.headingRegular}`}>
									Breed Information
								</h2>
								<hr />
								<p className={`${styles.bodyBigRegular}`}>
									{data.report_content.patientInfo}
								</p>
								<div className={`${styles.row} ${styles.mb20} ${styles.mt20}`}>
									{getBreedCols()}
								</div>
								{data['Polycat_unknown']>0&&
								<div className={`${styles.row} ${styles.mb20} ${styles.mt20}`}>
									{data['Polycat_unknown']}% Unknown origin
								</div>
								}
							</div>
						</div>
					</Page>
				</>
			)}
		</>
	);
};
